import {
    useState,
    useEffect,
    //useRef,
    //useReducer,
} from 'react';
import { useEnv } from "../system/useEnv";

// const FETCH_INIT = 'FETCH_INIT';
// const FETCH_SUCCESS = 'FETCH_SUCCESS';
// const FETCH_FAILURE = 'FETCH_FAILURE';


export const useUploadFile = ( p_route ) => {
    const [ host ] = useEnv();
    const [ data, setData ] = useState();
    const [ result, setResult ] = useState() ;

    useEffect( () => {
        if ( data ) {
            const uploadFile = async () => {
                try { 
                    const response = await fetch( "http://" + host + ":5000/upload", {
                        method: "POST",
                        body: data,
                    });
                    const answer = await response.json();
                    setResult( answer );
                    console.log( "uploadFile result: " + JSON.stringify( JSON.stringify( answer ) ) );
                } catch ( error ) {
                    console.log( "Error while talking to server: " + error.message );          
                }//catch
            }//uploadFile

            uploadFile();
        }//if

    }, [ data, host ] )//useEffect
    return [ setData, result, setResult ];
}//useUploadFile