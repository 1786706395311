import { React,
        useEffect,
        useState,
} from 'react';
import { useEnv } from "../../../system/useEnv";

function ListEvents() {
    const [ host ] = useEnv();
    const [ eventList, setEventList ] = useState();
    useEffect( () => {
        const getEventList = async () => { 
            try {
                const result = await fetch( "http://" + host + ":5000/eventList/", {
                    method: "GET",
                })
                const answer = await result.json();
                console.log( "getEventList: " + JSON.stringify( answer ) );
                setEventList( answer );
            } catch( err ) {
                console.log( "Fehler bei Kommunkation mit dem Backend:\n" + err );
            }//catch
        }//
        getEventList();
    }, [ host ])
    
    const listOutput = eventList.map( (e) => <li key={e.key}>{e.title}</li>);

    return (
        <>
            <div className='content'>
                <h1>Liste der Veranstaltungen</h1>
                <ul>
                    { listOutput }
                </ul>
            </div>
        </>
    )
}//ListEvents

export default ListEvents;