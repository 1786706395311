import { React,
} from 'react';
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className='col-100 col-t-100 footer' style={{ textAlign: 'center' }}>
            <Link className='navLink' style={{ fontSize: 14 }} to='imprint'>Impressum</Link>
        </div>
    )
}//Header

export default Footer;