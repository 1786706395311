import { React,
    useState,
    useEffect,
    useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDataPostApi } from "../data/useDataPostApi";
import { useUploadFile } from "../data/useUploadFile";
import { useParams } from "react-router-dom";
import GridLabel from '../design/GridLabel';
import PersonFieds from './PersonFields';
import { useEnv } from "../system/useEnv";

function MessageBox( props ) {
    return (
    <p>{props.message}</p>
    );
}//MessageBox

function PersonRegistration( props ) {
    const [ host, baseUrl ] = useEnv();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const formObject = { registerObj: register, formErrors: errors };
    const[ hnmEvent, setHnmEvent ] = useState( { title: props.event }  );
    const [ message, setMessage ] = useState( "Bitte füllen sie die Felder aus" );
    const [ person, setPerson ] = useState();
    const [ mailUnique, setMailUnique ] = useState( false );
    const [ doCheckMail, setDoCheckMail ] = useState( false )
    const [ fileError, setFileError ] = useState();
    const fileWatcher = useRef();
    const [ isKnown, setIsKnown ] = useState( false );
    const [ postPerson, resultPerson ] = useDataPostApi( '/person/add' );
    const [ sendMail ] = useDataPostApi( '/sendmail' );
    const [ checkMail, resultCheckmail ] = useDataPostApi( '/checkMail' )
    const [ mailTrigger, setMailTrigger ] = useState( false );
    const [ uploadFile, resultUpload ] = useUploadFile( "/upload" );
    let params = useParams();
    console.log( "Rendering ..." );
    let eventKey = props.event;
    if ( ! eventKey ) {
        eventKey = params.eventKey;
    }//if

     /**
     * get HNM Event from server
     */
    useEffect( () => {
        console.log( "get event" );
        const getEvent = async () => { 
            const result = await fetch( "http://" + host + ":5000/getevent/" + eventKey, {
                method: "GET",
            }).catch( ( err ) => {
                console.log( "Fehler bei Kommunkation mit dem Backend:\n" + err );
            }).then( response => response.json() );
            //console.log( "getEvent: " + JSON.stringify( result ) );
            setHnmEvent( result );
        }//getEvent
        getEvent();
    }, [ eventKey, host ])

    function mapPerson( data ) {
        const mappedPerson = {
            address: data.address,
            title: data.title,
            firstname: data.firstname,
            lastname: data.lastname,
            profession: data.profession,
            email: data.email,
            isKnown: isKnown,
            confirmedByUser: false,
            events: [ hnmEvent.key ],
        }
        return mappedPerson;
    }//mapPerson

    /**
     * Store person without file
     */
    useEffect( () => {
        setDoCheckMail( true );
        if( !person || !isKnown || !mailUnique ) {
            return;
        }//if
        console.log( 'No file: Sending person to server');
        postPerson( person );
        setMailTrigger( true );
    }, [ person, postPerson, isKnown, setMailTrigger, mailUnique, setDoCheckMail ])

    /**
     * Store person with file
     */
    useEffect( () => {
        setDoCheckMail( true );
        if ( !person || !resultUpload || !mailUnique ) {
            return;
        }//if
        let newPerson = JSON.parse( JSON.stringify( person ) );
        newPerson = { ...newPerson, files: [ resultUpload.filename ]  };
        console.log( JSON.stringify( newPerson ) );
        console.log( "sending person to server" );
        postPerson( newPerson ); 
        setMessage( person.firstname + " " + person.lastname + " wurde erfolgreich registriert" );
        setMailTrigger( true );
    }, [ resultUpload, person, postPerson, setMailTrigger, mailUnique, setDoCheckMail ])

    /**
     * Send confirmation mail
     */
    useEffect( () => {
        if( !person || !mailTrigger || !resultPerson.data || !mailUnique ) {
            return;
        }
        //console.log( resultPerson );
        const body = "Hallo " + person.firstname + " " + person.lastname + ",\n\n"
        + "vielen Dank für Ihre Anmeldung bei:\n " + hnmEvent.title
        + "\n\nBitte bestätigen Sie Ihre Registrierung hier: "
        + "\n" + baseUrl + "/confirmPerson/" +  resultPerson.data.insertedId
        + "\n\nIhre Eingaben werden überprüft. Sie hören bald von uns."
        + "\n\nIhre HNM-Id lautet: " + resultPerson.data.insertedId
        + "\n\nMit ihr und Ihrer E-Mail-Adresse können sie sich in Zukunft an weiteren Veranstaltungen anmelden."
        + "\n\nVielen Dank!"
        + "\n\nIhr Helfernetzwerk München"
        const mailMessage = {
            to: person.email,
            cc: "vs@xlent.de",
            text: body,
            subject: " HNM - " + hnmEvent.title,
        }
        sendMail( mailMessage );
    }, [ mailTrigger, sendMail, hnmEvent, person, resultPerson, mailUnique, baseUrl ])

    function checkFile( event ) {
        const file = event.target.files[ 0 ]; 
        let error = null;       
        const mimeMismatch = ( ( file.type !== "application/pdf" ) && !file.type.startsWith( 'image' ) );
        if ( mimeMismatch ) {
            error = "Keine gütige Datei ( Bitte PDF oder Bild wie PNG, JPEG... )";
        }//if
        if( !error ) {
            //TODO specify file size
            const allowedFileSize = 1 * 1024 *1024;
            if( file.size > allowedFileSize ) {
                error = "Datei zu groß. Maximal " + allowedFileSize/1024/1024 + " MB erlaubt";
            }//if
        }//if
        if ( error ) {
            event.target.value = null;
            setFileError( error );
        } else {
            setFileError( null );
        }
    }//checkfile

    useEffect( () => {
        if( !doCheckMail || !person ) {
            return;
        }//if
        console.log( "checking mail" );
        checkMail( { email: person.email } );
    }, [ doCheckMail, checkMail, person ])

    useEffect( () => {
        if( !resultCheckmail.data ) {
            return;
        }
        if ( !resultCheckmail.data.unique ) {
            setMessage( "Diese E-Mail ist bereits verwendet worden.")
        } else {
            setMailUnique( true );
            if ( !isKnown ) {
                let formData = new FormData();
                formData.append( 'file', fileWatcher.current.files[ 0 ] );
                uploadFile( formData );
            }//if
        }//else
        //console.log( JSON.stringify( resultCheckmail ) );
    }, [ resultCheckmail, isKnown, uploadFile ])

    const onSubmit = data => {
        if( !isKnown && ( !fileWatcher.current.value )  ){
            setFileError( "Bitte wählen Sie eine Datei aus" );
            return;
        }//if
        setPerson(  mapPerson( data ) );
        setDoCheckMail( true );
    }//onSubmit

    return(
        <div>
            <div><br/>Anmeldung: { hnmEvent && hnmEvent.title } </div>
            <div><MessageBox message={ message } /></div>
            <form id="uploadForm" onSubmit={ handleSubmit( onSubmit ) } >
                <div className="grid-container">
                    <PersonFieds { ...formObject }/>
                    <GridLabel label="Datei hochladen"/>
                    <div className='grid-input'>
                        <input ref={ fileWatcher } onChange={ (event) => { checkFile( event ) } }  type="file"></input>
                        <p>{ fileError }</p>
                    </div>
                    <GridLabel label="Sie waren bereits auf einem Symposium und sind persönlich bekannt"/>
                    <div className='grid-input'>
                        <input {...register( "known" )} type="checkbox" onClick={ () => { setIsKnown( !isKnown ) }}></input> Schon mal da gewesen
                    </div>
                    <GridLabel label= "Bitte überweseisen die Teilnahmegebühr von 30 Euro auf DE0815 123 4566. Erst danach können wir Sie zur Veranstaltung zulassen." />
                    <div className='grid-input'>
                        <input {...register( "willPay",  { required: true } )} type="checkbox"></input> Verstanden
                        <p>{ errors.willPay && 'Bitte bestätigen Sie, dass Sie überweisen werden.' }</p>
                    </div>
                    <GridLabel label="Ich habe die Datenschutzbestimmungen gelesen und stimme ihnen zu."/>
                    <div className='grid-input'>
                        <input {...register( "accepted",  { required: true } )} type="checkbox"></input> Verstanden
                        <p>{ errors.accepted && 'Bitte akzeptieren Sie die Datenschutzbestimmungen.' }</p>
                    </div>
                    <div>&nbsp;</div><div>&nbsp;</div>
                <div/><div  align="right"><input type="submit" value="Teilnehmer registrieren"/></div>
                </div>
            </form>
        </div>
    )//return

}//PersonRegistration


export default PersonRegistration;