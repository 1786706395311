import { React,
} from 'react';

function About() {
    return (
        <>
            <div style={{ clear: 'both' }}></div>
            <div className='col-50 col-t-50 content' style={{ display: 'inline-block' }}>
                <p>
                    Wir sind ein Netzwerk engagierter Menschen mit den
                    unterschiedlichsten medizinischen und therapeutischen
                    Fähigkeiten, die sich aufgrund der massiven Veränderungen im
                    Gesundheitswesen der letzten Jahrzehnte
                    zusammengeschlossen haben.
                </p>
                <p>
                    Wir haben uns zum Ziel gesetzt, eine humanere
                    Gesundheitsversorgung aller in München und Umland lebenden
                    Menschen sicherzustellen. Inspiriert wurden wir hierzu vom
                    Projekt ‚HUZ Hannover‘.
                </p>
            </div>
        </>
        )
}//About

export default About;