import{ useState } from 'react';


export const useEnv = () => {
    const [ host, setHost ] = useState();
    const [ baseUrl, setBaseUrl ] = useState();
    if  ( !host ) {
        const prodHost = 'hn-muc.de';
        const devHost = 'localhost';
        
        setHost( prodHost );
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ) {
            setHost( devHost );
        }//if
    }//if

    if( !baseUrl ) {
        const prodUrl = "https//hn-muc.de";
        const devUrl = "http://localhost:3000";
        setBaseUrl( prodUrl );
        if ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ) {
            setBaseUrl( devUrl );
        }//if
    }//if
    
    return [ host, baseUrl ];
}//useEnv

