import { React } from 'react';
import GridLabel from '../design/GridLabel';
//import { useForm } from 'react-hook-form';

/**
 * 
 * @param { UseFormRegist, UserFormError } @see Person 
 * @returns essential fields from a Person
 */
function PersonFieds( { registerObj, formErrors } ) {
    const register = registerObj;
    const errors = formErrors;
    
    return (
        <>
        <GridLabel label="Anrede"/>
        <div className='grid-input'>
            <select {...register("address", { required: true })} id="address" >
                <option value="Frau">Frau</option>
                <option value="Herr">Herr</option>
            </select>
        </div>
        <GridLabel label="Title"/>
        <div className='grid-input'>
            <input {...register( "title" )} id="title" type="text"/>
        </div>
        <GridLabel label="Vorname"/>
        <div className='grid-input'>
            <input {...register( "firstname", {required: true, maxLength: 30})} type="text"/>
            <p>{ errors.firstname && 'Bitte geben Sie Ihren Vornamen ein.' }</p>
        </div>
        <GridLabel label="Nachname"/>
        <div className='grid-input'>
            <input {...register( "lastname", {required: true, maxLength: 30})} type="text"/>
            <p>{ errors.lastname && 'Bitte geben Sie Ihren Nachnamen ein.' }</p>
        </div>
        <GridLabel label="Berufsbezeichnung"/>
        <div className='grid-input'>
            <input {...register( "profession", {required: true, maxLength: 30})} type="text"/>
            <p>{ errors.profession && 'Bitte geben Sie Ihre Berufsbezeichnung ein.' }</p>
        </div>
        <GridLabel label="E-Mail"/>
        <div className='grid-input'>
            <input
                {...register("email", {required: true, pattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/})}
                id="email" 
                type="text" 
            />
            <p>{ errors.email && 'Bitte geben Sie eine gültige Email-Adresse ein.' }</p>
        </div>
        </>
    )
}

export default PersonFieds