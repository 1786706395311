import { React,
} from 'react';
import { Link, useNavigate } from "react-router-dom";

function Header() {
  let navigate = useNavigate();
  //console.log( '>Header< NODE_ENV: ' + process.env.NODE_ENV )
  return (
    <>
    <div className='col-100 col-t-100 header'>
      HELFERNETZWERK MÜNCHEN
      <Link to="/">
        <img style={{ float: 'right' }} src='/images/HNM.png' alt="Helfernetzwerk München" height={56} width={64}/>
      </Link>
    </div>
    <div className='col-100 col-t-100 header'>
      <nav>
        <Link className='navLink' to="/">Start</Link> |{" "}
        <Link className='navLink' to="about">Über uns</Link> 
        <button className="navButton" onClick={() => { navigate( 'addPersonToEvent/3_HNM_SYMPOSIUM' ) } }>Anmelden beim 3. HNM Symposium</button>
      </nav>
    </div>
    </>
  )
}//Header

export default Header;