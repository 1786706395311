import { React,
} from 'react';
function Home() {
    return (
        <>
        <div style={{ clear: 'both' }}></div>
        <div className='col-50 col-t-50 content' style={{ display: 'inline-block' }}>
            <p>
                Viele politische Entscheidungen in den vergangenen Jahrzehnten haben nicht zu einer besseren und erst
                recht nicht zu einer menschlicheren
                Versorgungstruktur im Gesundheitswesen geführt.
            </p>
            <p>
                Der Druck, welcher auf Ärzten, Krankenschwestern
                und Therapeuten lastet, ist groß. Finanziell wie auch
                psychisch sind die Anforderungen stets gewachsen,
                zum Leidwesen für die Menschen, die auf
                medizinische oder therapeutische Hilfe
                angewiesen sind.
            </p>
            <p><b>
                Es braucht einen Wandel!
            </b></p>
            <p>
                Es braucht ein Besinnen auf die Dinge, die unserer
                Gesundheit dienlich sind und ein menschliches
                Miteinander ermöglichen. Es braucht eine
                unabhängige Wissenschaft, evidenzbasierte
                Entscheidungen und die Offenheit in Dialog zu treten.
                Finanzielle Interessen Einzelner dürfen nicht länger
                über die Gesundheit vieler Menschen entscheiden.
            </p>
        </div>
        </>
    )
}//Home

export default Home;