import { React,
        useEffect,
        useState,
        useCallback,
} from 'react';
import { useDataPostApi } from '../../../data/useDataPostApi'

function ListPersons() {
    const [ listOutput, setListOutput ] = useState();
    //const [ currentPerson, setCurrentPerson ] = useState();
    //const [ hasPaid, setHasPaid ] = useState( false );
    //const [ isHnmConfirmed, setIsHnmConfirmed ] = useState( false );
    const [ getPersons, listPersons ] = useDataPostApi( '/getEventPersons' );
    const [ updatePerson, resultUpdatePerson ] = useDataPostApi( '/updatePerson' );
    console.log( "rendering" );

    const cbHasPaid = useCallback( ( person ) => {
        person.hasPaid = true;
        updatePerson( person );
    }, [  updatePerson ])

    const cbHnmConfirm = useCallback( ( person ) => {
        person.confirmedByHNM = true; 
        updatePerson( person );
    }, [ updatePerson ])    

    useEffect( () => {
        getPersons( { eventKey: "3_HNM_SYMPOSIUM" } )
    }, [ getPersons ] )

    useEffect( () => {
        if( !listPersons.data  || listOutput  ) {
            return;
        }
        setListOutput( listPersons.data.map( ( person ) => renderline( person ) ) );
        
        function renderline( person ) {
            return (
                <p key={ person._id }>
                    { person.firstname } { person.lastname } &nbsp;
                    | Bestätigung Teilnehmer: { person.confirmedByUser ? (
                        <b>&nbsp;Ja&nbsp;</b>
                    ) : (
                        <b>&nbsp;Nein&nbsp;</b>
                    )}
                    | Bestätigung HNM:{ person.confirmedByHNM ? (
                        <b>&nbsp;Ja&nbsp;</b>
                    ) : (
                        <>
                        <b>&nbsp;Nein&nbsp;</b>
                        <button onClick={ () => { cbHnmConfirm( person ) } }>HNM bestätigen</button>
                        </>    
                    )}
                    | Bezahlt: { person.hasPaid ? (
                        <b>&nbsp;Ja&nbsp;</b>
                    ) : (
                        <>
                        <b>&nbsp;Nein&nbsp;</b>
                        <button onClick={ () => { cbHasPaid( person ) } }>hat bezahlt</button>
                        </>    
                    )}
                </p>
            )
        }//renderLine
    
    }, [ listPersons, setListOutput, cbHasPaid, cbHnmConfirm, listOutput, resultUpdatePerson ] )

    useEffect( () => {
        console.log( "reloading" );
        if( !resultUpdatePerson.data ) {
            return;
        }
        window.location.reload( false );
    }, [ resultUpdatePerson.data ])

    return (
        <>
            <div className='content'>
                <h1>Liste der Teilnehmer</h1>
                <ul>
                    { listOutput }
                </ul>
            </div>
        </>
    )
}//ListEvents

export default ListPersons;