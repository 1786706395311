import { React,
        useEffect,
        useState,
} from 'react';
import { useParams } from "react-router-dom";
import { useDataPostApi } from "../data/useDataPostApi";
import { useEnv } from "../system/useEnv";

function ConfirmPerson( props ) {
    const [ host ] = useEnv();
    const [ confirmed, setConfirmed ] = useState( false );
    const [ person, setPerson ] = useState();
    //const [ userId, setUserId ] = useState();
    const [ confirmPerson ] = useDataPostApi( "/confirmperson" );
    let params = useParams();
    let userId = params.userId ;
    console.log( "rendering: " + JSON.stringify( person ) );

    useEffect( () => {
        if ( person ) {
            return;
        }//if
        const getPerson = async () => { 
            const result = await fetch( "http://" + host + ":5000/getperson/" + userId, {
                method: "GET",
            }).catch( ( err ) => {
                console.log( "Fehler bei Kommunikation mit dem Backend:\n" + err );
            }).then( response => response.json() );
            console.log( "getPerson: " + JSON.stringify( result ) );
            setPerson( result );
        }//getPerson
        getPerson();
    }, [ userId, person, setPerson, host ])

    useEffect( () => {
        if( !confirmed ) {
            return;
        }
        confirmPerson( {
            userId: userId,
            confirmedByUser: confirmed,
        } );
    }, [ confirmed, confirmPerson, userId, host ])

    function handleClick() {
        setConfirmed( true )
    }

    return(
        <>
        <div style={{ clear: 'both' }}></div>
        <div className='col-50 col-t-50 content' style={{ display: 'inline-block' }}>
            <h4>Bestätigen Sie bitte Ihre Registrierung bei HNM</h4>
            <div>
                <p>
                    { person && person.firstname && " " && person.lastname && " - " && person.email }
                </p>
            </div>
            <button onClick={ ()=>{ handleClick() } }>Ja, das bin ich</button>
        </div>
        </>
    )
}//ConfirmPerson

export default ConfirmPerson;