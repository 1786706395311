import {
    useState,
    useEffect,
    //useRef,
    //useReducer,
} from 'react';
import { useEnv } from "../system/useEnv";

// const FETCH_INIT = 'FETCH_INIT';
// const FETCH_SUCCESS = 'FETCH_SUCCESS';
// const FETCH_FAILURE = 'FETCH_FAILURE';


export const useDataPostApi = ( p_route ) => {
    const [ host ] = useEnv();
    const [ route, setRoute ] = useState( p_route )
    const [ json, setJson ] = useState();
    const[ result, setResult ] = useState( { error: null,
                                             data: null,});
    //setRoute( p_route );
    //let url = "http://" +  host + ":5000" + route;
    useEffect( () => {
        if ( json ) {
            const postData = async () =>  { 
                try {
                    const response = await fetch( "http://" +  host + ":5000" + route, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify( json ),
                    })
                    const answer = await response.json();
                    setResult( { error: null, data:  answer } );
                    console.log( "posted data - answer from server\n: " + JSON.stringify( answer ) );          
                } catch ( error ) {
                    setResult( { error: error.message, data: null } );
                    console.log( "Error while talking to server: " + error.message );          
                }//catch
            }//postData
            postData();
        }//if
    }, [ json, route, host ] )//useEffect
    return [ setJson, result, setRoute ];
}//useDataPostApi

//export default useDataPostApi;