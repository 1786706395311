import { React } from 'react';
import PersonRegistration from './person/PersonRegistration';
import ConfirmPerson from './person/ConfirmPerson';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Header from './site/Header';
import Footer from './site/Footer';
import Home from './site/content/Home';
import About from './site/content/About';
import Imprint from './site/content/Imprint';
import ListEvents from './site/content/admin/ListEvents';
import ListPersons from './site/content/admin/ListPersons';

function HNM() {
  
  return (
    <div className="App">
      <Router>
        <Header/>
        <div className='content col-100 col-t-100'>
          <Routes>
            <Route path='/' element={ <Home/>} />
            <Route path='/about' element={ <About/>} />
            <Route path='/imprint' element={ <Imprint/>} />
            <Route path='/eventlist' element={ <ListEvents/>} />
            <Route path='/personlist' element={ <ListPersons/>} />
            <Route path='/addPerson' element={ <PersonRegistration event='3_HNM_SYMPOSIUM'/>} />
            <Route path='addPersonToEvent/:eventKey' element={ <PersonRegistration/> } />
            <Route path='confirmPerson/:userId' element={ <ConfirmPerson/> } />
          </Routes>
        </div>
        <Footer/>
      </Router>
    </div>
  );
}

export default HNM;
